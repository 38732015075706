import * as React from 'react';
import { Typography, Icon } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';

import { FlexBox } from 'shared/components/FlexBox.jsx';
import UnsignedWaiver from 'shared/components/icons/UnsignedWaiver.jsx';
import Gift from 'shared/components/icons/Gift.jsx';

import useHover from 'shared/utils/hooks/useHover.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    margin: '10px 0',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 15,
    height: 32,
    padding: 5,
    transition: 'background-color 200ms ease-in-out',
    backgroundColor: uhColors.backgroundGray,
  },
  avatarContainer: {
    width: 24,
    height: 24,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
  },
  spaceContainer: {
    height: 24,
    width: 36,
  },
  unpaidCircle: {
    borderRadius: '50%',
    backgroundColor: 'var(--color-unpaid-red)',
    margin: '10px 12px',
    height: 8,
    width: 8,
  },
  nameContainer: {
    alignSelf: 'center',
    flexGrow: 1,
    position: 'relative',
    top: 1,
    cursor: 'pointer',
    color: uhColors.activeBlue,
  },
  containerHovering: hovering =>
    hovering ? { backgroundColor: uhColors.backgroundGray } : {},
  iconButton: {
    padding: 0,
    width: 24,
    height: 24,
  },
  unsignedWaiver: {
    display: 'flex',
    marginRight: 40,
  },
};

function AttendeeListCard({
  avatar,
  onRemove,
  unpaid,
  client,
  eventId,
  noSpaceOnLeft,
}) {
  const [ref, isHovered] = useHover();

  const showClient = () => {
    ClientProfileDrawerActions.mounted({ clientId: client?.id, eventId });
  };
  return (
    <div style={styles.container} ref={ref}>
      {!noSpaceOnLeft && (
        <div style={styles.spaceContainer}>
          {unpaid && <div style={styles.unpaidCircle} />}
        </div>
      )}
      <div
        style={{
          ...styles.rightContainer,
          ...styles.containerHovering(isHovered),
        }}
      >
        <div style={styles.avatarContainer}>{avatar}</div>
        <div
          role="presentation"
          style={styles.nameContainer}
          onClick={showClient}
        >
          {/** Use body3 variant when it is released */}
          <Typography variant="body1">{client.name()}</Typography>
        </div>
        {!client.get('has_accepted_terms') &&
          !client.get('managed_by_id') &&
          client.isTodayBirthDay() && (
            <FlexBox>
              <span>
                <UnsignedWaiver />
              </span>
              <span style={styles.unsignedWaiver}>
                <Gift color="#4AD669" style={{ margin: '0 5px' }} />
              </span>
            </FlexBox>
          )}
        {!client.get('has_accepted_terms') &&
          !client.get('managed_by_id') &&
          !client.isTodayBirthDay() && (
            <FlexBox>
              <span style={styles.unsignedWaiver}>
                <UnsignedWaiver />
              </span>
            </FlexBox>
          )}
        {client.get('has_accepted_terms') &&
          client.get('managed_by_id') &&
          client.isTodayBirthDay() && (
            <FlexBox>
              <span style={styles.unsignedWaiver}>
                <Gift color="#4AD669" />
              </span>
            </FlexBox>
          )}
        <div>
          {onRemove && (
            <div>
              <IconButton onClick={onRemove} style={styles.iconButton}>
                <Icon name="close" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AttendeeListCard;
