import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Typography, Icon } from '@upperhand/playmaker';
import useHover from 'shared/utils/hooks/useHover.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    margin: '10px 0',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 32,
    padding: 5,
    borderRadius: 15,
    transition: 'background-color 200ms ease-in-out',
    backgroundColor: uhColors.backgroundGray,
  },
  avatarContainer: {
    width: 24,
    height: 24,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
  },
  spaceContainer: {
    height: 24,
    width: 36,
  },
  nameContainer: {
    alignSelf: 'center',
    flexGrow: 1,
    position: 'relative',
    top: 1,
  },
  containerHovering: hovering =>
    hovering ? { backgroundColor: uhColors.backgroundGray } : {},
  iconButton: {
    padding: 0,
    width: 24,
    height: 24,
  },
  newItem: {
    border: '1px solid var(--color-green)',
  },
};

function StaffListCard({
  avatar,
  name,
  isNew = false,
  spaceOnLeft = true,
  onRemove,
}) {
  const [ref, isHovered] = useHover();

  return (
    <div style={styles.container} ref={ref}>
      {spaceOnLeft && <div style={styles.spaceContainer} />}
      <div
        style={{
          ...styles.rightContainer,
          ...styles.containerHovering(isHovered),
          ...(isNew && styles.newItem),
        }}
      >
        <div style={styles.avatarContainer}>{avatar}</div>
        <div style={styles.nameContainer}>
          {/** Use body3 variant when it is released */}
          <Typography variant="body1">{name}</Typography>
        </div>
        <div>
          {onRemove && (
            <IconButton onClick={onRemove} style={styles.iconButton}>
              <Icon name="close" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default StaffListCard;
